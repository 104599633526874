import React from "react";
import SEO from "../../components/common/seo.js";
import Layout from "../../components/common/layout";
import Navigation from "../../components/navigation/navigation";
import Header from "../../components/sections/header";
import Map from "../../components/sections/map";
import Contact from "../../components/sections/contact";
import { Dot, Container } from "../../components/global";
import styled from "styled-components";

const RynekKapitalowy = () => (
  <Layout>
    <SEO
      title='Rynek Kapitałowy'
      description='Fachowa pomoc prawna związana z funkcjonowaniem szeroko pojętego rynku kapitałowego. Kancelaria posiada szerokie doświadczenie w ramach wielu transakcji realizowanych tak na rynku krajowym (dopuszczenie i wprowadzenie do obrotu akcji na rynku regulowanym GPW), jak i zagranicznym (Deutsche Börse AG – Niemcy; Borsa Italiana S.p.A. – Włochy; NASDAQ – USA).'
    />
    <Navigation />
    <Header />
    <Article id='page'>
      <Title>Rynek Kapitałowy</Title>
      <Container>
        <p>
          Fachowa pomoc prawna związana z funkcjonowaniem szeroko pojętego rynku
          kapitałowego. Kancelaria posiada szerokie doświadczenie w ramach wielu
          transakcji realizowanych tak na rynku krajowym (dopuszczenie i
          wprowadzenie do obrotu akcji na rynku regulowanym GPW), jak i
          zagranicznym (Deutsche Börse AG – Niemcy; Borsa Italiana S.p.A. –
          Włochy; NASDAQ – USA).
        </p>
        <List>
          <Dot>zezwolenie na prowadzenie działalności maklerskiej;</Dot>
          <Dot>
            zezwolenie na utworzenie banku, a następnie na podjęcie działalności
            przez już utworzony bank (tzw. licencja bankowa);
          </Dot>
          <Dot>
            fundusze inwestycyjne (m.in. uzyskanie zezwoleń na utworzenie lub
            rozszerzenie działalności towarzystw funduszy inwestycyjnych,
            alternatywnych spółek inwestycyjnych (ASI), firm inwestycyjnych oraz
            dystrybutorów tytułów uczestnictwa funduszy inwestycyjnych);
          </Dot>
          <Dot>
            doradztwo prawne związane z wejściem spółki na rynek regulowany GPW
            S.A. (w tym sporządzanie prospektu emisyjnego lub memorandum
            informacyjnego);
          </Dot>
          <Dot>
            doradztwo prawne związane z dopuszczeniem spółki do alternatywnego
            systemu obrotu na rynku NewConnect lub Catalyst;
          </Dot>
          <Dot>wycofywanie spółki z rynku publicznego (tzw. delisting);</Dot>
          <Dot>
            obowiązki informacyjne i notyfikacyjne spółek publicznych (tzw.
            Rozporządzenie MAR, przepisy krajowe oraz regulacje GPW, NewConnect
            i Catalyst);
          </Dot>
          <Dot>
            transakcje zbycia lub nabycia znacznych pakietów akcji spółek
            publicznych;
          </Dot>
          <Dot>wezwania na sprzedaż akcji spółek publicznych;</Dot>
          <Dot>komunikacja z inwestorami i rynkiem;</Dot>
          <Dot>
            wrogie oraz przyjazne przejęcia (w tym również reverse takeover)
            oraz obrona przed przejęciami;
          </Dot>
          <Dot>emisja obligacji;</Dot>
          <Dot>tzw. buy–back (odkup akcji); </Dot>
          <Dot>transakcje w ramach Towarowej Giełdy Energii S.A.;</Dot>
          <Dot>reprezentacja przed KNF, KDPW oraz GPW. </Dot>
        </List>
      </Container>
    </Article>
  </Layout>
);

export default RynekKapitalowy;

const Title = styled.h4`
  margin-top: 75px;
`;

const List = styled.ul`
  padding: 40px 0px 150px;
  li {
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
    line-height: 1.5;
  }
`;
const Article = styled.article`
  padding-top: 20px;
  /* border: 1px solid red; */
`;
